import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import styled from 'react-emotion'
import tinycolor from 'tinycolor2'

import PageContainer from '../components/common/PageContainer'
import PageTitle from '../components/common/PageTitle'
import Layout from "../layouts/index";

import logo from '../assets/images/logo.svg'

const PageBody = styled.div`
  color: ${props => props.theme.white};
  align-self: center;
  font-family: inherit;
  text-align: center;
  font-size: ${props => props.theme.paddingSize / 2}px;
  line-height: 1.25;
  margin-bottom: 1.5em;

  & p {
    margin-bottom: 0.75rem;
    text-align: center;
  }

  @media (min-width: 960px) {
    & p {
      font-size: 1.2rem;
      line-height: 1.5;
      margin-bottom: 1.2rem;
    }
  }
`

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;

  & a {
    color: ${props => props.theme.white};
    text-decoration: none;
    background-color: ${props => props.theme.purple};
    box-shadow: 0px 4px 0px 0px ${props => props.theme.red}7F;
    border-radius: 10px;
    padding: 15px;
    margin-top: ${props => props.theme.paddingSize / 4}px;
    text-align: center;
    position: relative;

    &:hover {
      background-color: ${props =>
        tinycolor(props.theme.purple)
          .darken()
          .toHexString()};
    }

    & p {
      margin-bottom: 0.25rem;
      line-height: 1;
      font-size: 14px;
      text-transform: uppercase;
    }

    & h3 {
      margin-bottom: 0;
      font-family: ${props => props.theme.headerFont};
      color: ${props => props.theme.yellow};
    }
  }
  @media (min-width: 768px) {
    & {
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 3rem;
    }

    & a {
      &:first-child {
        width: 95%;
        margin-bottom: 2rem;
      }

      margin: 0;
      width: 45%;

      & h3 {
        font-size: 30px;
      }
    }
  }

  @media (min-width: 960px) {
    & a {
      width: 30%;
      &:first-child {
        width: 30%;
        margin-bottom: 0;
      }
    }
  }
`
const Logo = styled.div`
  font-size: 9rem;
  text-align: center;
  color: #e3cf34;
  margin-bottom: 2rem;
  margin-top: 5rem;

  @media (min-width: 768px) {
    margin-top: 0rem;
    font-size: 10rem;
  }
`

const HomePageContainer = styled.div`
  padding: ${props => props.theme.paddingSize / 2}px;

  @media (min-width: 768px) {
    padding: 0;
  }
`

const IndexPage = props => (
  <Layout location={props.location}>
    {props.data && 
      <PageContainer>
        <HomePageContainer>
          <Logo>
            <img src={logo} alt="The Hitchhiker's Guide to Javascript" />
          </Logo>
          <PageTitle>
            <span>The Hitchhiker's Guide to Javascript</span>
            <span>The Hitchhiker's Guide to Javascript</span>
          </PageTitle>
          <hr
            css={{
              width: '47%',
              margin: '0 auto 20px auto',
              borderColor: 'rgba(0,0,0,0.25)',
            }}
          />
          <PageBody>
            <p>In the beginning JavaScript was created.</p>
            <p>
              This has made a lot of people very angry and been widely regarded as a
              bad move.
            </p>
          </PageBody>
          {props.data ? (
            <BottomSection>
              <Link to={`/introduction/`}>
                <p>Get Started</p>
                <h3>Introduction</h3>
              </Link>
              <Link
                to={`/${props.data.latestPage.edges[0].node.chapter.slug}/${
                  props.data.latestPage.edges[0].node.path
                }/`}
              >
                <p>Latest Page</p>
                <h3>{props.data.latestPage.edges[0].node.title}</h3>
              </Link>
              <Link to="/table-of-contents">
                <p>All Chapters</p>
                <h3>Table of Contents</h3>
              </Link>
            </BottomSection>
          ) : null}
        </HomePageContainer>
      </PageContainer>
    }
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery {
    latestPage: allContentfulPage(
      sort: { fields: [createdAt], order: DESC }
      limit: 1
    ) {
      edges {
        node {
          title
          path
          chapter {
            slug
          }
        }
      }
    }
    firstChapter: allContentfulChapter(filter: { chapterNumber: { eq: 1 } }) {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`
