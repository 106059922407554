import React from 'react'
import styled from 'react-emotion'

const PageTitle = styled.h1`
  color: ${props => (props.backdrop ? props.theme.red : props.theme.yellow)};
  position: ${props => (props.backdrop ? 'absolute' : 'relative')};
  ${props =>
    props.backdrop
      ? 'top: 3px; width: 100%; z-index: 1; margin-bottom: 0;'
      : 'z-index: 2;'}
  text-shadow: -2px 0 ${props => props.theme.red},0 2px ${props =>
  props.theme.red},2px 0 ${props => props.theme.red},0 -2px ${props =>
  props.theme.red};
  text-align: center;
  font-size: ${props => props.theme.paddingSize * 0.8}px;
  font-family: ${props => props.theme.headerFont};
  line-height: 1.25;
  @media (min-width: 768px) {
    & {
      font-size: ${props => props.theme.paddingSize * 1.35}px;
    }
  }
`

export default props => {
  if (Array.isArray(props.children)) {
    return (
      <div css={{ position: 'relative' }}>
        <PageTitle>{props.children[0]}</PageTitle>
        <PageTitle backdrop>{props.children[1]}</PageTitle>
      </div>
    )
  }
  return <PageTitle>{props.children}</PageTitle>
}
